<script>
export default {
  name: 'Capitalize',
  props: {
    value: {
      type: [String],
      required: true,
    },
  },
  computed: {
    formattedValue() {
      return this.value.charAt(0).toUpperCase() + this.value.slice(1);
    },
  },
};
</script>

<template lang="pug">
span {{ formattedValue }}
</template>
